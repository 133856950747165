import { ApplicationConfig, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HttpClientModule, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule } from '@appRouting/app-routing.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@appCore/store/root-reducer';
import { documentsModuleMetaReducers } from '@appCore/store/document-aside';
import { EffectsModule } from '@ngrx/effects';
import { rootEffects } from '@appCore/store/root-effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { RouterModule } from '@angular/router';
import { GlobalErrorHandlerModule } from '@appCore/glb/services/error-handling/glb-error-handling.module';
import { EntityStoreModule } from '@appCore/entity-store/entity-store.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CustomRouterStateSerializer } from '@appCore/store/router/router';
import { GlobalErrorHandlerService } from '@appCore/glb/services/error-handling/glb-error-handler.service';
import {
  AuthGuard,
  CURRENT_USER_ROLES,
  currentUserInfo,
  currentUserRolesFactory,
  USER_INFO_TOKEN,
} from '@appRouting/guards/auth-guard.service';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { MatomoInjector, MatomoModule, MatomoTracker } from 'ngx-matomo';
import { glbHttpErrorInterceptorProvider } from '@appCore/glb/glb-api/glb-http-error.interceptor';
import { HttpCacheInterceptorProvider } from '@appCore/interceptors/http-cache.interceptor';

registerLocaleData(localeRu);

const MAIN_MODULES = [
  StoreModule.forRoot(reducers, {
    metaReducers: documentsModuleMetaReducers,
    // Найти и исправить все места где происходит мутация данных, затем включить рантайм-проверки
    runtimeChecks: {
      strictStateImmutability: false,
      strictActionImmutability: false,
    },
  }),
  StoreRouterConnectingModule.forRoot(),
  StoreDevtoolsModule.instrument({
    maxAge: environment.reduxMaxStoreAge,
    name: 'NPA store',
    logOnly: environment.production,
    connectInZone: true,
  }),
  EffectsModule.forRoot(rootEffects),
  MatomoModule,
  HttpClientModule,
  BrowserModule,
  RouterModule,
  AppRoutingModule,
  GlobalErrorHandlerModule,
  BrowserAnimationsModule,
  EntityStoreModule,
  MatomoModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    // Выключил пока не понятно что с загрукой multipart
    enabled: false,
    registrationStrategy: 'registerImmediately',
  }),
];

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(...MAIN_MODULES),
    provideAnimations(),
    provideHttpClient(),
    provideClientHydration(),
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
    [{ provide: ErrorHandler, useClass: GlobalErrorHandlerService }],
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: CURRENT_USER_ROLES, useFactory: currentUserRolesFactory(), deps: [AuthGuard] },
    { provide: USER_INFO_TOKEN, useFactory: currentUserInfo(), deps: [AuthGuard] },
    glbHttpErrorInterceptorProvider,
    HttpCacheInterceptorProvider,
    MatomoInjector,
    MatomoTracker,
  ],
};
