import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment/moment';
import { combineLatest, takeUntil } from 'rxjs';
import { Unsubscriber } from '../../../core/unsubscriber';
import { DomEventService } from '../../../services/dom-event.service';
import { WINDOW } from '../../../shared/tokens/window.token';

@Component({
  selector: 'app-input-timer',
  templateUrl: './input-timer.component.html',
  styleUrls: ['./input-timer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTimerComponent),
      multi: true,
    },
  ],
})
export class InputTimerComponent extends Unsubscriber implements ControlValueAccessor, OnDestroy, AfterViewInit {
  @Input()
  id: string = '';
  @Input()
  controlName: string = '';
  @Input()
  label: string = '';
  @Input()
  isBlackTheme: boolean = false;
  @Input()
  showClearButton: boolean = false;

  @ViewChild('inputTimer', { static: true })
  inputTimerRef!: ElementRef;
  @ViewChild('clockIcon', { static: true }) clockIconRef!: ElementRef;
  @ViewChild('scrollHours', { static: true }) scrollHoursRef!: ElementRef;
  @ViewChild('scrollMinutes', { static: true }) scrollMinutesRef!: ElementRef;
  @ViewChild('timeSelector') timeSelectorRef!: ElementRef;

  public isTimerSelectorActive: boolean = false;
  public isTimeSelectorUpPosition: boolean = false;
  public hours: number[] = [];
  public minutes: number[] = [];
  public controlValue: string = '';
  public hoursInSelector: string[] = [];
  public minutesInSelector: string[] = [];
  public isFocusedHours: boolean = false;
  public isFocusedMinutes: boolean = false;
  public isDisabled: boolean = false;

  private selectedHours: number = 0;
  private selectedMinutes: number = 0;
  private readonly TIME_SELECTOR_ITEMS = 7;

  constructor(
    @Inject(WINDOW) private readonly windowRef: Window,
    private readonly domEvent: DomEventService,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.openSelectEventSubscribe();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  onChange(value: string): void {}

  writeValue(value: string): void {
    if (value) {
      this.setTime(value);
      this.setSelectorTimeValues();
      this.controlValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  public getHourStatus(hour: string): boolean {
    return this.selectedHours === Number(hour);
  }

  public getMinuteStatus(minut: string): boolean {
    return this.selectedMinutes === Number(minut);
  }

  public controlValueChange(value: string): void {
    this.setSelectorTimeValues();
    this.applyValue(value);
  }

  public setCurrentTime(): void {
    const currentTime = moment().format('HH:mm');
    this.applyValue(currentTime);
  }

  public clearTime(): void {
    this.applyValue('');
  }

  public selectHour(hour: string): void {
    this.selectedHours = Number(hour);
    this.isFocusedHours = true;
    this.isFocusedMinutes = false;
    this.controlValue = hour + ':' + this.getSplittedTimePartOrDefault(this.controlValue?.split(':')[1]);
    this.onChange(this.controlValue);
  }

  public selectMinute(minute: string): void {
    this.selectedMinutes = Number(minute);
    this.isFocusedMinutes = true;
    this.isFocusedHours = false;
    this.controlValue = this.getSplittedTimePartOrDefault(this.controlValue?.split(':')[0]) + ':' + minute;
    this.onChange(this.controlValue);
  }

  public onHoursScroll(event: WheelEvent): void {
    event.preventDefault();
    const hoursPerDay = 23;
    if (event.deltaY > 0) {
      this.hoursInSelector = this.scrollDown(this.hoursInSelector, hoursPerDay);
      return;
    }
    if (event.deltaY < 0) {
      this.hoursInSelector = this.scrollUp(this.hoursInSelector, hoursPerDay);
    }
  }

  public onMinutesScroll(event: WheelEvent): void {
    event.preventDefault();
    const minutesPerDay = 59;
    if (event.deltaY > 0) {
      this.minutesInSelector = this.scrollDown(this.minutesInSelector, minutesPerDay);
      return;
    }
    if (event.deltaY < 0) {
      this.minutesInSelector = this.scrollUp(this.minutesInSelector, minutesPerDay);
    }
  }

  private applyValue(value: string): void {
    this.controlValue = value;
    this.setTime(value);
    this.onChange(value);
  }

  private openSelectEventSubscribe(): void {
    combineLatest([
      this.domEvent.confirmOwnershipOfMouseDown(this.clockIconRef?.nativeElement),
      this.domEvent.confirmOwnershipOfMouseDown(this.scrollHoursRef?.nativeElement),
      this.domEvent.confirmOwnershipOfMouseDown(this.scrollMinutesRef?.nativeElement),
    ])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([isClockIconClicked, isScrollHoursClicked, isScrollMinutesClicked]) => {
        if (this.isDisabled) {
          this.isTimerSelectorActive = false;
          this.cdr.markForCheck();
          return;
        }

        if (isClockIconClicked) {
          this.setSelectorTimeValues();
          this.openTimeSelector();
          this.isTimerSelectorActive = true;
          this.cdr.markForCheck();
          return;
        }

        if (isScrollHoursClicked || isScrollMinutesClicked) {
          this.isTimerSelectorActive = true;
          this.cdr.markForCheck();
          return;
        }

        this.isTimerSelectorActive = false;
        this.cdr.markForCheck();
      });

    this.domEvent
      .confirmOwnershipOfWheel(this.timeSelectorRef?.nativeElement)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((isTimeSelectorWheeled) => {
        if (!isTimeSelectorWheeled) {
          this.isTimerSelectorActive = false;
          this.cdr.markForCheck();
        }
      });
  }

  private getSplittedTimePartOrDefault(splittedPart: string): string {
    return splittedPart ?? '00';
  }

  private setTime(value: string): void {
    const isValidDate = value !== 'Invalid date'; // isValid() тут не помог

    if (isValidDate && value) {
      const time = moment(value, 'HH:mm', true);
      this.selectedHours = time.hour();
      this.selectedMinutes = time.minutes();
      return;
    }

    this.selectedHours = 0;
    this.selectedMinutes = 0;
  }

  private setTimeFormat(number: number): string {
    return String(number).padStart(2, '0');
  }

  private setSelectorTimeValues(): void {
    this.hoursInSelector = this.createNumberList(this.selectedHours);
    this.minutesInSelector = this.createNumberList(this.selectedMinutes);
  }

  private openTimeSelector(): void {
    const timeSelectorHeight = 36 * this.TIME_SELECTOR_ITEMS; // высота селектора вмещает 7 чисел
    const timeInputHeight = 40;
    const xPosition = this.inputTimerRef.nativeElement.getBoundingClientRect().left;
    const yPosition = this.inputTimerRef.nativeElement.getBoundingClientRect().top;
    const delta = this.windowRef.innerHeight - yPosition;
    this.timeSelectorRef.nativeElement.style.left = xPosition + 'px';
    this.timeSelectorRef.nativeElement.style.top = yPosition + timeInputHeight + 'px';
    this.isTimeSelectorUpPosition = delta < timeSelectorHeight + timeInputHeight;
  }

  private createNumberList(initSelectedValue: number): string[] {
    let selectedValue = initSelectedValue;
    let listValues: string[] = [];

    while (listValues.length < this.TIME_SELECTOR_ITEMS) {
      listValues = [...listValues, this.setTimeFormat(selectedValue)];
      selectedValue++;
    }

    return listValues;
  }

  private scrollUp(valueList: string[], timeUnit: number): string[] {
    const firstValue = Number(valueList[0]);
    let newValueList = [...valueList];
    newValueList.unshift(this.setTimeFormat(firstValue - 1 === -1 ? timeUnit : firstValue - 1));
    newValueList.pop();
    return newValueList;
  }

  private scrollDown(valueList: string[], timeUnit: number): string[] {
    const lastValue = Number(valueList[valueList.length - 1]);
    let newValueList = [...valueList];
    newValueList.push(this.setTimeFormat(lastValue + 1 > timeUnit ? 0 : lastValue + 1));
    newValueList.shift();
    return newValueList;
  }
}
