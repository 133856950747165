import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@appShared/components/shared-components.module';
import { ReportGridCellValuePipe } from '@appShared/modules/report-grid/report-grid-cell-value.pipe';
import { ReportGridSortPipe } from '@appShared/modules/report-grid/report-grid-sort.pipe';
import { ReportGridComponent } from '@appShared/modules/report-grid/report-grid.component';
import { SharedPipesModule } from '@appShared/pipes/shared-pipes.module';

@NgModule({
  imports: [CommonModule, SharedComponentsModule, SharedPipesModule, RouterModule],
  declarations: [ReportGridComponent, ReportGridSortPipe, ReportGridCellValuePipe],
  exports: [ReportGridComponent],
})
export class ReportGridModule {}
