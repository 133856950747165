import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-infinite-scroll',
  template: '',
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {
  private readonly ACTIVE_ZONE = 150; // в пикселях
  @Output() endReached = new EventEmitter();
  private unlisten!: () => void;
  private endLock = false;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const parent = this.elRef.nativeElement.parentElement;

    this.unlisten = this.renderer.listen(parent, 'scroll', (e: Event) => {
      const target = e.target as HTMLElement;
      const scrollTop = target.scrollTop + target.clientHeight;
      const endReached = scrollTop + this.ACTIVE_ZONE >= target.scrollHeight;

      if (endReached && !this.endLock) {
        this.endReached.emit();
        this.endLock = true;
      } else if (!endReached) {
        this.endLock = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.unlisten();
  }
}
