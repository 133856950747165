import { ErrorMessageModel } from '@models/error-message.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getErrorMessgeFromStore = createFeatureSelector('errorMessage');

export const getErrorMessageInfo = createSelector(getErrorMessgeFromStore, (state: ErrorMessageModel) => ({
  message: state.message,
  stackTrace: state.stackTrace,
  isSmall: state.isSmall,
}));

export const isShowErrorPopup = createSelector(
  getErrorMessgeFromStore,
  (state: ErrorMessageModel) => state.message || state.stackTrace
);
