import { Subject } from 'rxjs';

/**
 * Класс-отписчик
 *
 * Дублируется в проектах: web, admin
 * Редактировать в обоих местах
 */
export class Unsubscriber {
  public ngUnsubscribe$: Subject<void> = new Subject();

  protected unsubscribe(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
