import { Injectable } from '@angular/core';
import { DocumentPermissions } from '@appPermissions/enums/document-permissions.enum';
import { DocumentsItemModel } from '@models/document-composition/documents-item.model';
import { DocumentTypesEnum } from '@models/enums/document-types.enum';
import { DocumentGroupsEnum } from '@sharedEnums/document-groups.enum';

import { ReduxService } from '@appCore/services/redux/redux.service';
import { getUserInfo } from '@appCore/store/user-info';
import { getDocumentRouteTree } from '@appDocuments/documents-route/store/document-route';
import { Observable, of } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { EmployeeRouteTypes } from '@appDocuments/documents-route/enums/employee-route-types.enum';
import { VertexState } from '@appDocuments/documents-route/enums/vertex-state.enum';
import { DocumentCompositionApiService } from '@appShared/api/document-composition/document-composition.api.service';
import { TreeWithEmployeeModel } from '@appShared/models/tree-with-employee';
import { DocumentVersionState } from '@models/document-composition/document-version-state.enum';

@Injectable({
  providedIn: 'root',
})
export class DocumentCompositionItemLogicService {
  constructor(private redux: ReduxService, private documentCompositionApi: DocumentCompositionApiService) {}

  public isShowButtonAddMainDocument(): Observable<boolean> {
    return this.redux.selectStore(getDocumentRouteTree).pipe(
      filter((res) => !!res),
      withLatestFrom(this.redux.selectStore(getUserInfo)),
      map(
        ([tree, userInfo]) => ({ tree, currentUserEmployee: userInfo.currentUser.employee } as TreeWithEmployeeModel)
      ),
      map(this.canHideAddMainDocumentButton)
    );
  }

  private canHideAddMainDocumentButton(treeEmployeeModel: TreeWithEmployeeModel): boolean {
    const { tree, currentUserEmployee } = treeEmployeeModel;
    if (tree.length) {
      const firstStage = tree[0];

      const assignmentSteps = firstStage.subPhases[0].steps.flat();
      const currentUserPoint = assignmentSteps.find((step) => step.metadata?.employee.id === currentUserEmployee.id);
      const isPointInactive = currentUserPoint?.metadata.state !== VertexState.active;

      const isCurrentUserPointInitiator = currentUserPoint?.metadata.pointRoleId === EmployeeRouteTypes.initiator;
      const isFirstStageContainOneSubPhase = firstStage.subPhases.length === 1;

      // если юзер инициатор и точка неактивная и этап содержит один подэтап
      return isCurrentUserPointInitiator && isPointInactive && isFirstStageContainOneSubPhase;
    }
    return false;
  }

  public getDocumentName(document: DocumentsItemModel): string {
    if (!document) {
      return '';
    }

    const allowedToShowNumber =
      document.number &&
      ![DocumentTypesEnum.ADDITIONAL, DocumentTypesEnum.DOCUMENT_PUBLICATION].includes(document.documentType.id);

    return allowedToShowNumber ? `${document.name} ${document.number}` : document.name;
  }

  public canViewDocument(document: DocumentsItemModel): boolean {
    return this.hasDocumentVersions(document);
  }

  public canEditDocument(document: DocumentsItemModel, permissions: string[]): boolean {
    if (this.isDocumentDeleted(document)) {
      return false;
    }

    if (!this.hasDocumentVersions(document)) {
      return false;
    }

    return (
      this.hasPermission(permissions, DocumentPermissions.DOCUMENT_UPDATE) ||
      this.hasPermission(permissions, DocumentPermissions.EDIT_DOCUMENT_PUBLICATION)
    );
  }

  /**
   * Проверяет, можно ли импортировать текущий док
   *
   * @param permissions - права на проверяемый документ
   */
  public canImport(permissions: string[]): boolean {
    return this.hasPermission(permissions, DocumentPermissions.DOCUMENT_REVISION_IMPORT);
  }

  public canDeleteDocument(document: DocumentsItemModel, permissions: string[]): boolean {
    if (this.isDocumentDeleted(document)) {
      return false;
    }

    if (this.isPublicationVersion(document)) {
      return (
        this.hasPermission(permissions, DocumentPermissions.DOCUMENT_PUBLICATION_IN_OAU_DOCUMENT_SECTION_DELETE) ||
        this.hasPermission(permissions, DocumentPermissions.DOCUMENT_PUBLICATION_IN_DOCUMENT_PUBLICATION_SECTION_DELETE)
      );
    }

    return this.hasPermission(permissions, DocumentPermissions.DOCUMENT_DELETE);
  }

  public canRefresh(document: DocumentsItemModel, permissions: string[]): boolean {
    if (!this.hasDocumentVersions(document)) {
      return false;
    }

    if (this.isDocumentDeleted(document)) {
      return false;
    }

    if (!this.hasType(document)) {
      return false;
    }

    if (this.isPublicationVersion(document)) {
      return false;
    }
    const documentGroupsIntersection = [DocumentGroupsEnum.additional, DocumentGroupsEnum.oau].includes(
      document.documentGroup
    );
    if (documentGroupsIntersection) {
      return this.canRefreshPoliticForAdditionalAndOAUDocs(permissions);
    }

    return this.canRefreshForOtherDocs(document, permissions);
  }

  public canRefreshHeader(document: DocumentsItemModel, permissions: string[]): boolean {
    if (document.versionsLoading) {
      return false;
    }
    const isDocument = document.documentType.id === DocumentTypesEnum.DOCUMENT;

    if (!isDocument) {
      return false;
    }

    if (document.deleted) {
      return false;
    }

    return permissions.includes(DocumentPermissions.DOCUMENT_REFRESH_HEADER);
  }

  /** Проверка документа на тип и значение признака актуальности */
  public isDocumentForSignActual(document: DocumentsItemModel): boolean {
    const allowedToRefreshFromTemplateTypes = [
      DocumentTypesEnum.AGREEMENT_SHEET,
      DocumentTypesEnum.AGREEMENT_SHEET_ES,
      DocumentTypesEnum.AGREEMENT_SHEET_ADDITIONAL_INVESTMENT_PROJECT,
      DocumentTypesEnum.AGREEMENT_SHEET_ADDITIONAL_INVESTMENT_PROJECT_ES,
    ];

    const isTypeAllowed = allowedToRefreshFromTemplateTypes.includes(document.documentType.id);

    return document && !document.actual && isTypeAllowed;
  }

  private hasDocumentVersions(document: DocumentsItemModel): boolean {
    return !document.versionError && !!document.versions?.length;
  }

  private isDocumentDeleted(document: DocumentsItemModel): boolean {
    return document.deleted;
  }

  private hasPermission(documentPermissions: string[], requiredPermission: string): boolean {
    return documentPermissions.includes(requiredPermission);
  }

  private isPublicationVersion(document: DocumentsItemModel): boolean {
    return [DocumentTypesEnum.DOCUMENT_PUBLICATION, DocumentTypesEnum.DOCUMENT_PUBLICATION_LPA].includes(
      document.documentType.id
    );
  }

  private hasType(document: DocumentsItemModel): boolean {
    return Boolean(document && document.documentType);
  }

  private hasTemplate(document: DocumentsItemModel): boolean {
    return Boolean(document.templateId);
  }

  private isAllowedDocumentTypeForRefresh(document: DocumentsItemModel): boolean {
    const allowedToRefreshFromTemplateTypes = [
      DocumentTypesEnum.AGREEMENT_SHEET,
      DocumentTypesEnum.AGREEMENT_SHEET_ES,
      DocumentTypesEnum.DISAGREEMENT_SHEET,
      DocumentTypesEnum.EXPLANATORY_NOTE,
      DocumentTypesEnum.ANALYTICAL_NOTE,
      DocumentTypesEnum.AMENDMENT,
      DocumentTypesEnum.PROTOCOL_EXTRACT_WEB,
      DocumentTypesEnum.AGREEMENT_SHEET_ADDITIONAL_INVESTMENT_PROJECT,
      DocumentTypesEnum.AGREEMENT_SHEET_ADDITIONAL_INVESTMENT_PROJECT_ES,
      DocumentTypesEnum.REMARKS_SHEET,
    ];

    return allowedToRefreshFromTemplateTypes.includes(document.documentType.id);
  }

  /** Для документов группы Согласования и Документы ОАУ ПМ - отдельная политика кнопки Обновить */
  private canRefreshPoliticForAdditionalAndOAUDocs(permissions: string[]): boolean {
    return this.hasPermission(permissions, DocumentPermissions.DOCUMENT_REFRESH);
  }

  private canRefreshForOtherDocs(document, permissions): boolean {
    if (!this.hasTemplate(document)) {
      return false;
    }

    if (!this.isAllowedDocumentTypeForRefresh(document)) {
      return false;
    }

    return this.hasPermission(permissions, DocumentPermissions.DOCUMENT_UPDATE);
  }

  public checkRemarksSheetUpdateDisabled(document: DocumentsItemModel): Observable<boolean> {
    const isDocumentRemarksSheet = this.isDocumentRemarksSheet(document.documentType.id);
    const hasVersionManualMode = document.versions?.some(
      (version) => version.state === DocumentVersionState.MANUAL_MODE
    );

    if (isDocumentRemarksSheet && hasVersionManualMode) {
      return this.documentCompositionApi.hasRemarksSheetUpdateProhibition(document.documentPackageId, document.id);
    }

    return of(false);
  }

  /** Проверка документа на соответствие ЛУЗ */
  private isDocumentRemarksSheet(documentTypeId: number): boolean {
    const remarkSheetTypes = [
      DocumentTypesEnum.REMARKS_SHEET,
      DocumentTypesEnum.INTERNAL_REMARK_SHEET
    ];

    return  remarkSheetTypes.includes(documentTypeId);
  }
}
