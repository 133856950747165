import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** Вспомогательный сервис для контролов */
@Injectable({ providedIn: 'root' })
export class DomEventService {
  /** Подтверидить/опровергнуть, что клики мыши
   *  находятся на указанном элементе и его потомках.
   *
   *  @param DOMOfComponent - DOM элемента-владельца
   */
  public confirmOwnershipOfClick(DOMOfComponent: HTMLElement): Observable<boolean> {
    return fromEvent(document, 'click').pipe(
      map((event: Event) => this.checkOwnershipInParents(event.target as HTMLElement, DOMOfComponent))
    );
  }

  /** Подтверидить/опровергнуть, что зажатая кнопка мыши
   *  находятся на указанном элементе и его потомках.
   *
   *  @param DOMOfComponent - DOM элемента-владельца
   */
  public confirmOwnershipOfMouseDown(DOMOfComponent: HTMLElement): Observable<boolean> {
    return fromEvent(document, 'mousedown').pipe(
      map((event: Event) => this.checkOwnershipInParents(event.target as HTMLElement, DOMOfComponent))
    );
  }

  /** Подтверидить/опровергнуть, что прокрутка колесика мыши
   *  находятся на указанном элементе и его потомках.
   *
   *  @param DOMOfComponent - DOM элемента-владельца
   */
  public confirmOwnershipOfWheel(DOMOfComponent: HTMLElement): Observable<boolean> {
    return fromEvent(document, 'wheel').pipe(
      map((event: Event) => this.checkOwnershipInParents(event.target as HTMLElement, DOMOfComponent))
    );
  }

  /** рекурсивный поиск владельца DOM элемента */
  private checkOwnershipInParents(targetElement: HTMLElement, DOMOfOwner: HTMLElement): boolean {
    if (!targetElement) {
      return false;
    }

    if (targetElement.nodeName === 'BODY') {
      return false;
    }

    if (targetElement === DOMOfOwner) {
      return true;
    }

    return this.checkOwnershipInParents(targetElement?.parentNode as HTMLElement, DOMOfOwner);
  }
}
