import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { SelectBaseComponent } from '@appShared/components/controls/select/select-base-component/select.base';
import { SelectFilterPipe } from '@appShared/pipes/select-filter.pipe';
import { DomEventService, InputKeyCode } from '@libs/projects/component-lib/src/public-api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: [
    '../../control-base/control-base.component.scss',
    '../select-base-component/select.base.scss',
    './select.component.scss',
  ],
})
export class SelectComponent extends SelectBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() changeFilter = new EventEmitter<string>();
  /**Список элементов выпадающего списка */
  @Input()
  optionsList: any[];
  /** Нужна ли строчка "выбрать"  */
  @Input()
  needFilter: boolean;
  /** Нужен ли цветовой индикатор - если да, то из какого поля его брать */
  @Input()
  colorIndicatorField: string;
  /** Нужна ли иконка - если да, то из какого поля его брать */
  @Input()
  iconIndicatorField: string;
  /** Нужна ли всплывающая подсказка */
  @Input()
  isTooltipDisabled = false;
  /** Нужен ли падинг снизу */
  @Input()
  paddingBottom = true;
  @Input()
  /** Позиционирование текста ошибки */
  errorPosition: string;
  @ViewChild('inputComponent') inputComponent: ElementRef;
  @ViewChild('selectBoxDivEl') selectBoxDivEl: ElementRef;

  /** Фильтрующий инпут */
  public filterInput: string;
  /** Индекс выделенного элемента */
  public indexOfSelectedOption = -1;

  constructor(
    private selectFilter: SelectFilterPipe,
    private domEventService: DomEventService,
    private cdr: ChangeDetectorRef,
    elementRef: ElementRef,
    redux: ReduxService
  ) {
    super(elementRef, redux);
  }
  @HostListener('window:resize')
  onResize() {
    if (this.isShowOptions) {
      this.hideOptionsList();
    }
  }

  ngOnInit(): void {
    this.change.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => (this.filterInput = ''));
  }

  ngAfterViewInit(): void {
    this.closeOptionsListWhenClickedOutside();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  /**
   * Метод для отображения и скрытия списка опций
   */
  public toggleOptionsList(): void {
    if (!this.isShowOptions) {
      this.showOptionsList();
    } else {
      this.hideOptionsList();
    }

    this.resetSelectedOptions();
  }

  /** Ловим событие ввода в поля инпута */
  public onChanged(event): void {
    const value = event.target.value.trim();

    this.changeFilter.emit(value);
  }

  /**
   * callback фокуса на инпуте
   */
  public onFocus(): void {
    this.filterInput = '';
  }

  /**
   * callback блюра на инпуте
   */
  public onBlur(): void {
    this.control.markAsTouched();
  }

  /**
   * callback блюра на селекте
   */
  public onBlurSelect(): void {
    this.control.markAsTouched();
  }

  /**
   * callback нажатия кнопки клавиатуры
   */
  public onKeyDown(event): void {
    const keyCode = event.keyCode ? event.keyCode : event.which;

    switch (keyCode) {
      case InputKeyCode.tab:
        this.isShowOptions = false;
        break;

      case InputKeyCode.arrowDown:
        event.preventDefault();
        this.onArrowDownPress();
        break;

      case InputKeyCode.arrowUp:
        event.preventDefault();
        this.onArrowUpPress();
        break;

      case InputKeyCode.enter:
        event.preventDefault();
        this.onKeyEnterPress();
        break;

      default:
        if (!this.needFilter) {
          event.preventDefault();
          break;
        }

        this.resetSelectedOptions();
    }
  }

  public onClearControlValue(): void {
    this.filterInput = '';
    super.onClearControlValue();
  }

  public onFocusSelect(): void {
    if (!this.optionsList) {
      this.inputComponent?.nativeElement.focus();
    }
    this.showOptionsList();
  }

  /**
   * событие нажатия кнопки вниз
   */
  private onArrowDownPress(): void {
    if (this.indexOfSelectedOption + 1 < this.filtredOptionsList.length) {
      this.indexOfSelectedOption++;
    }
  }

  /**
   * событие нажатия кнопки вверх
   */
  private onArrowUpPress(): void {
    if (this.indexOfSelectedOption > 0) {
      this.indexOfSelectedOption--;
    }
  }

  /**
   * событие нажатия кнопки Enter
   */
  private onKeyEnterPress(): void {
    if (!this.filtredOptionsList.length || this.indexOfSelectedOption < 0) {
      return;
    }

    this.selectOption(this.filtredOptionsList[this.indexOfSelectedOption]);
  }

  /** список отфильтрованных пользователем опций */
  private get filtredOptionsList(): any[] {
    return this.selectFilter.transform(this.optionsList, this.optionLabel, this.filterInput);
  }

  /**
   * сбросить выделение всех элементов списка
   */
  private resetSelectedOptions(): void {
    this.indexOfSelectedOption = -1;
  }

  /** закрывать список опций при клике вне области компоненты */
  private closeOptionsListWhenClickedOutside(): void {
    if (this.selectBoxDivEl?.nativeElement) {
      this.domEventService
        .confirmOwnershipOfClick(this.selectBoxDivEl.nativeElement)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((isOwnClick: boolean) => {
          if (!isOwnClick) {
            this.hideOptionsList();
            this.cdr.detectChanges();
          }
        });
    }
  }
}
