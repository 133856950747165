import { getUserInfo } from '@appCore/store/user-info';
import { ApproverTypeInShortLoop } from '@appDocuments/documents-route/enums/approver-type-in-short-loop.enum';
import { EmployeeRouteTypes } from '@appDocuments/documents-route/enums/employee-route-types.enum';
import { PhaseTypeCode } from '@appDocuments/documents-route/enums/phase-type-code.enum';
import { RoutePhaseTypes } from '@appDocuments/documents-route/enums/route-phase-types.enum';
import { VertexState } from '@appDocuments/documents-route/enums/vertex-state.enum';
import { RouteStaticHelpers } from '@appDocuments/documents-route/helpers/route-static-helpers';
import { TreePhaseModel } from '@appDocuments/documents-route/models/tree/tree-phase.model';
import {
  getDocumentRoute,
  getDocumentRouteStore,
  getDocumentRouteStoreData,
  getDocumentRouteTree,
} from '@appDocuments/documents-route/store/document-route/selectors/common-selectors';
import {
  getActivePointForCurrentEmployee,
  getActivePointOnMainVertical,
  getActivePointsOnMainVertical,
  getAllCurrentUserPoints,
  getInactiveInitiationPoints,
} from '@appDocuments/documents-route/store/document-route/selectors/route-logic-selectors';
import { RouteTreeResponseModel } from '@appShared/api/documents-route/models/route-tree.response/route-tree.response.model';
import { VertexResponseModel } from '@appShared/api/documents-route/models/route-tree.response/vertex.response.model';
import { TaskType } from '@appShared/enums/task-type.enum';
import { SystemPointIds } from '@models/enums/system-point-ids.enum';
import { UserInfoResponseModel } from '@models/user-info.model';
import { createSelector } from '@ngrx/store';
import { OibRoles } from '@models/enums/oib-roles.enum';

export const getIsShowStartDevelopmentButton = createSelector(
  getActivePointForCurrentEmployee,
  getDocumentRouteStoreData,
  (activePoint, route) => {
    if (
      route &&
      activePoint &&
      activePoint.phaseTypeId === RoutePhaseTypes.projectCreatedInitiation &&
      !route.routeTemplateId &&
      [EmployeeRouteTypes.executor, EmployeeRouteTypes.coexecutor, EmployeeRouteTypes.initiator].includes(
        activePoint.pointRoleId
      )
    ) {
      return true;
    }
    return false;
  }
);

export const getIsShowStartApprovementButton = createSelector(
  getActivePointForCurrentEmployee,
  getActivePointsOnMainVertical,
  getDocumentRoute,
  (activePoint: VertexResponseModel, allActivePoints: VertexResponseModel[], route: RouteTreeResponseModel) => {
    if (!activePoint) {
      return false;
    }

    const hasActiveAgreementTask = allActivePoints.some(
      (p) => p.lastTask && p.lastTask.taskType.id === TaskType.agreement
    );

    if (hasActiveAgreementTask) {
      return false;
    }

    const isCorrectPhase = [RoutePhaseTypes.projectCreatedRework, RoutePhaseTypes.projectCreatedDevelopment].includes(
      activePoint.phaseTypeId
    );

    const isCorrectTaskType = [TaskType.development, TaskType.revision].includes(activePoint.lastTask.taskType.id);

    if (!(isCorrectPhase && isCorrectTaskType)) {
      return false;
    }

    const nextPoint = RouteStaticHelpers.getNextNotDeletedPoints(route, activePoint)[0];
    const nextPointIsProjectCreatedApprovement = nextPoint.phaseTypeId === RoutePhaseTypes.projectCreatedApprovement;
    const activeUserIsCoexecutor = activePoint.pointRoleId === EmployeeRouteTypes.coexecutor;

    if (!nextPointIsProjectCreatedApprovement && !activeUserIsCoexecutor) {
      return false;
    }

    if (activePoint.lastTask.taskType) {
      return [EmployeeRouteTypes.author, EmployeeRouteTypes.coexecutor].includes(activePoint.pointRoleId);
    }
  }
);

export const getIsShowTotalRecallButton = (pointId?: number) =>
  createSelector(
    getDocumentRouteStoreData,
    getAllCurrentUserPoints,
    getUserInfo,
    getActivePointsOnMainVertical,
    (route, userPoints, userInfo, activePoints) => {
      if (!route || !userPoints) {
        return false;
      }

      /* Определение актуальной активной точки, если их несколько
       * актуальной считаю ту активную точку, которая находится на том же этапе, что и точка пользователя
       */
      const allowedPhaseIds = userPoints
        .filter((point) => point.id === pointId || !pointId)
        .map((point) => point.phaseId);
      const activePoint = activePoints.filter((point) => allowedPhaseIds.includes(point.phaseId)).reverse()[0];

      if (!activePoint) {
        return false;
      }

      const activePhases = [
        RoutePhaseTypes.projectCreated,
        RoutePhaseTypes.projectCreatedInitiation,
        RoutePhaseTypes.projectCreatedDevelopment,
        RoutePhaseTypes.projectCreatedApprovement,
        RoutePhaseTypes.projectCreatedRework,
        RoutePhaseTypes.preliminaryApprovement,
        RoutePhaseTypes.preliminaryApprovementInitiation,
        RoutePhaseTypes.preliminaryApprovementApprovement,
        RoutePhaseTypes.responsibleExecutor,
        RoutePhaseTypes.responsibleExecutorApprovement,
        RoutePhaseTypes.questionProvidedInitiation,
        RoutePhaseTypes.questionProvidedApprovement,
        RoutePhaseTypes.oauPm,
        RoutePhaseTypes.oauPmInitiation,
        RoutePhaseTypes.oauPmExpertise,
        RoutePhaseTypes.oauPmApprovement,
        RoutePhaseTypes.oauRegistration,
        RoutePhaseTypes.oauRegistrationInitiation,
        RoutePhaseTypes.oauRegistrationApprovement,
        RoutePhaseTypes.puPm,
        RoutePhaseTypes.puPmInitiation,
        RoutePhaseTypes.puPmApprovement,
        RoutePhaseTypes.projectApproved,
        RoutePhaseTypes.projectApprovedInitiation,
        RoutePhaseTypes.projectApprovedApprovement,
        RoutePhaseTypes.userPhaseApprovement,
      ];
      const completedStates = [VertexState.completed, VertexState.withdrawn, VertexState.completedWithRemarks];

      if (!activePhases.includes(activePoint.phaseTypeId)) {
        return false;
      }

      /** Завершенная точка с активного этапа имеющая после себя активную точку */
      const coordinatorPointWithActiveStages = getCoordinatorPointWithActiveStages(
        route,
        userInfo,
        activePoint,
        completedStates
      );

      /** TODO: Если пользователь имеет активную и параллельную заверешенную точку (у которой следующая точка активная) подставляем coordinatorPointWithActiveStages */
      // точка-инициатор отзыва ПД на маршруте
      const recallInitiatorPoint = coordinatorPointWithActiveStages ?? activePoint;

      if (!recallInitiatorPoint || !completedStates.includes(recallInitiatorPoint.state)) {
        return false;
      }

      const nextPoint: VertexResponseModel = getNextPoint(route, activePoint, recallInitiatorPoint);

      const isLastPointOfStage: boolean = nextPoint.phaseId !== recallInitiatorPoint.phaseId;
      const isUserIntroduceOrController: boolean = [
        EmployeeRouteTypes.introducer,
        EmployeeRouteTypes.controller,
      ].includes(recallInitiatorPoint.pointRoleId);

      const authorPoint = route.vertexes.find((p) => p.pointRoleId === EmployeeRouteTypes.author);

      if (
        authorPoint.employee.id === userInfo.currentUser.employee.id &&
        recallInitiatorPoint.pointRoleId === EmployeeRouteTypes.author
      ) {
        return false;
      }

      if (!activePhases.includes(activePoint.phaseTypeId)) {
        return false;
      }

      if (![VertexState.active, VertexState.redirected].includes(nextPoint?.state)) {
        return false;
      }

      if (isUserIntroduceOrController) {
        return false;
      }

      if (isLastPointOfStage) {
        return false;
      }

      if (isNextSidePointCompleted(route, activePoint, recallInitiatorPoint, completedStates)) {
        return false;
      }

      return true;
    }
  );

const getCoordinatorPointWithActiveStages = (
  route: RouteTreeResponseModel,
  userInfo: UserInfoResponseModel,
  activePoint: VertexResponseModel,
  completedStates: VertexState[]
) => {
  /** Точки пользователя с активного этапа */
  const userPointsForActiveStages = route.vertexes.filter(
    (v) =>
      !!v.employee && userInfo.currentUser.employee.id === v.employee.id && v.phaseTypeId === activePoint.phaseTypeId
  );

  /** Завершенная точка с активного этапа имеющая после себя активную точку */
  return userPointsForActiveStages.find((point) => {
    const isCompletedPoint = completedStates.includes(point.state);
    const nextPoint1 = RouteStaticHelpers.getNextNotSystemPoints(route, point)[0];
    const isNextPointActive = !!nextPoint1 && [VertexState.active, VertexState.redirected].includes(nextPoint1.state);

    return isCompletedPoint && isNextPointActive;
  });
};

const getNextSidePoint = (
  route: RouteTreeResponseModel,
  nextPoint: VertexResponseModel,
  recallInitiatorPoint: VertexResponseModel
): VertexResponseModel[] =>
  route.vertexes.filter(
    (point) =>
      point.phaseId === nextPoint.phaseId && point.level > nextPoint.level && point.level > recallInitiatorPoint.level
  );

const getNextPoint = (
  route: RouteTreeResponseModel,
  activePoint: VertexResponseModel,
  recallInitiatorPoint: VertexResponseModel
) => {
  /** Следующая точка от общей родительской точки */
  const siblingPoint = RouteStaticHelpers.getSiblingPoints(route, recallInitiatorPoint).find(
    (point) => activePoint.id === point.id
  );
  if (siblingPoint?.id === activePoint.id) {
    return siblingPoint;
  }
  return RouteStaticHelpers.getNextNotSystemPoints(route, recallInitiatorPoint)[0];
};

const isNextSidePointCompleted = (
  route: RouteTreeResponseModel,
  nextPoint: VertexResponseModel,
  recallInitiatorPoint: VertexResponseModel,
  completedStates: VertexState[]
): boolean => {
  const nextSidePoint = getNextSidePoint(route, nextPoint, recallInitiatorPoint);
  const isAnySidePointCompleted: boolean = nextSidePoint.some((point) => completedStates.includes(point.state));
  const sidePointsNextPoint = RouteStaticHelpers.getSidePoints(route, nextPoint);
  const isSideNextPointsActive: boolean = sidePointsNextPoint.some((point) => completedStates.includes(point.state));

  if (
    recallInitiatorPoint.level === 1 &&
    nextPoint.active &&
    !!nextSidePoint.length &&
    isSideNextPointsActive &&
    isAnySidePointCompleted
  ) {
    return false;
  }

  if (nextPoint.completed) {
    return false;
  }
};

export const getIsShowTotalReturnButton = createSelector(
  getDocumentRouteStoreData,
  getUserInfo,
  getActivePointOnMainVertical,
  (route, userInfo, activePoint) => {
    if (!route || !userInfo || !activePoint) {
      return false;
    }

    if (activePoint.pointRoleId === EmployeeRouteTypes.author && activePoint.state === VertexState.active) {
      return false;
    }

    const employee = userInfo.currentUser.employee;
    const lastAuthorPoint = RouteStaticHelpers.getLastAuthorPoint(route);

    if (!lastAuthorPoint) {
      return false;
    }
    const activePoints = route.vertexes.filter((p) => [VertexState.active, VertexState.redirected].includes(p.state));

    if (!activePoints.length) {
      return false;
    }

    const isInvalidPhase = activePoints.some((p) =>
      [RoutePhaseTypes.oauRegistrationApprovement, RoutePhaseTypes.oauRegistrationInitiation].includes(p.phaseTypeId)
    );

    if (isInvalidPhase) {
      return false;
    }

    const currentEmployeeIsAuthor = lastAuthorPoint.employee.id === employee.id;
    const authorTaskType = lastAuthorPoint.lastTask.taskType.id;
    const isAllowedTaskType = [TaskType.agreement, TaskType.revision].includes(authorTaskType);

    if (isAllowedTaskType && currentEmployeeIsAuthor && lastAuthorPoint.state === VertexState.active) {
      return true;
    }

    return currentEmployeeIsAuthor && lastAuthorPoint.state === VertexState.completed;
  }
);

export const getIsShowApproveButton = createSelector(getActivePointForCurrentEmployee, (activePoint) => {
  if (!activePoint) {
    return false;
  }

  const activePhases = [
    RoutePhaseTypes.projectCreatedApprovement,
    RoutePhaseTypes.projectApprovedApprovement,
    RoutePhaseTypes.preliminaryApprovementApprovement,
    RoutePhaseTypes.questionProvidedApprovement,
    RoutePhaseTypes.responsibleExecutorApprovement,
    RoutePhaseTypes.puPmApprovement,
    RoutePhaseTypes.oauPmApprovement,
    RoutePhaseTypes.oauRegistrationApprovement,
    RoutePhaseTypes.concilationApprovement,
  ];
  if (!activePhases.some((a) => a === activePoint.phaseTypeId)) {
    return false;
  }

  return activePoint.pointRoleId === EmployeeRouteTypes.coordinator;
});

export const getIsShowInternalReturnButton = createSelector(
  getActivePointForCurrentEmployee,
  getDocumentRouteStoreData,
  (activePoint, route) => {
    if (!activePoint) {
      return false;
    }

    const havePreviousPointsOnSamePhase = RouteStaticHelpers.getPreviousPoints(route, activePoint).some(
      (v) => v.phaseId === activePoint.phaseId && !v.active
    );

    if (activePoint.level > 1 && !havePreviousPointsOnSamePhase) {
      return false;
    }

    const allovedPhases = [RoutePhaseTypes.projectCreatedApprovement];
    if (!allovedPhases.some((a) => a === activePoint.phaseTypeId)) {
      return false;
    }

    const allovedRoles = [EmployeeRouteTypes.coordinator, EmployeeRouteTypes.leader];

    return allovedRoles.includes(activePoint.pointRoleId);
  }
);

export const getIsShowInternalReturnButtonOnOuterApprovement = createSelector(
  getActivePointForCurrentEmployee,
  getDocumentRouteStoreData,
  (activePoint, route) => {
    if (!activePoint) {
      return false;
    }

    const havePreviousPointsOnSamePhase = RouteStaticHelpers.getPreviousPoints(route, activePoint).some(
      (v) =>
        !v.isSystem && v.phaseId === activePoint.phaseId && !v.active && v.pointRoleId !== EmployeeRouteTypes.introducer
    );

    if (!havePreviousPointsOnSamePhase) {
      return false;
    }

    const allovedPhases = [
      RoutePhaseTypes.puPmApprovement,
      RoutePhaseTypes.questionProvidedApprovement,
      RoutePhaseTypes.responsibleExecutorApprovement,
      RoutePhaseTypes.projectApprovedApprovement,
      RoutePhaseTypes.preliminaryApprovementApprovement,
      RoutePhaseTypes.oauPmApprovement,
      RoutePhaseTypes.oauRegistrationApprovement,
      RoutePhaseTypes.concilationApprovement,
    ];
    if (!allovedPhases.some((a) => a === activePoint.phaseTypeId)) {
      return false;
    }

    const allovedRoles = [EmployeeRouteTypes.coordinator, EmployeeRouteTypes.leader, EmployeeRouteTypes.executor];

    return allovedRoles.includes(activePoint.pointRoleId);
  }
);

export const getIsShowDecideButton = createSelector(getActivePointForCurrentEmployee, (activePoint) => {
  if (!activePoint) {
    return false;
  }

  if (activePoint.phaseTypeId !== RoutePhaseTypes.projectCreatedApprovement) {
    return false;
  }

  return activePoint.pointRoleId === EmployeeRouteTypes.leader;
});

export const getIsShowApproveAfterRevorkButton = createSelector(getActivePointForCurrentEmployee, (activePoint) => {
  if (!activePoint) {
    return false;
  }

  if (
    !(
      activePoint.phaseTypeId === RoutePhaseTypes.projectCreatedDevelopment &&
      activePoint.lastTask.taskType.id === TaskType.revision
    )
  ) {
    return false;
  }

  return !!activePoint;
});

export const getIsShowDecideOuterButton = createSelector(
  getActivePointForCurrentEmployee,
  getDocumentRouteStoreData,
  getInactiveInitiationPoints,
  (activePoint, route, inactiveInitiationPoints) => {
    if (!activePoint) {
      return false;
    }

    if (inactiveInitiationPoints.length) {
      return false;
    }

    const activePhases = [
      RoutePhaseTypes.projectApprovedInitiation,
      RoutePhaseTypes.projectApprovedApprovement,
      RoutePhaseTypes.preliminaryApprovementInitiation,
      RoutePhaseTypes.preliminaryApprovementApprovement,
      RoutePhaseTypes.questionProvidedInitiation,
      RoutePhaseTypes.questionProvidedApprovement,
      RoutePhaseTypes.responsibleExecutorInitiation,
      RoutePhaseTypes.responsibleExecutorApprovement,
      RoutePhaseTypes.puPmInitiation,
      RoutePhaseTypes.puPmApprovement,
      RoutePhaseTypes.concilationInitiation,
      RoutePhaseTypes.concilationApprovement,
    ];
    if (!activePhases.some((i) => i === activePoint.phaseTypeId)) {
      return false;
    }

    if (activePoint.pointRoleId === EmployeeRouteTypes.executor) {
      const prevPoint = RouteStaticHelpers.getPreviousPoints(route, activePoint)[0];
      const nextPoint = RouteStaticHelpers.getNextPoints(route, activePoint)[0];
      const parentPhaseId = route.phases.find((phase) => phase.id === activePoint.phaseId).parentPhase.id;
      const prevPointParentPhaseId =
        prevPoint.id === SystemPointIds.START_POINT_ID
          ? 0
          : route.phases.find((phase) => phase.id === prevPoint.phaseId).parentPhase.id;
      const nextPointParentPhaseId =
        nextPoint.id === SystemPointIds.STOP_POINT_ID
          ? 0
          : route.phases.find((phase) => phase.id === nextPoint.phaseId).parentPhase.id;

      if (parentPhaseId !== prevPointParentPhaseId) {
        return false;
      }
      if (parentPhaseId === nextPointParentPhaseId) {
        return false;
      }
      return prevPoint.employee.id === activePoint.employee.id && prevPoint.pointRoleId === EmployeeRouteTypes.leader;
    }

    return activePoint.pointRoleId === EmployeeRouteTypes.leader;
  }
);

export const getIsShowSendToLeaderButton = createSelector(getActivePointForCurrentEmployee, (activePoint) => {
  if (!activePoint) {
    return false;
  }

  const activePhases = [
    RoutePhaseTypes.projectCreatedDevelopment,
    RoutePhaseTypes.projectApprovedApprovement,
    RoutePhaseTypes.preliminaryApprovementApprovement,
    RoutePhaseTypes.questionProvidedApprovement,
    RoutePhaseTypes.responsibleExecutorApprovement,
    RoutePhaseTypes.puPmApprovement,
    RoutePhaseTypes.projectCreatedRework,
  ];
  if (
    !(
      activePhases.some((a) => a === activePoint.phaseTypeId) && activePoint.lastTask.taskType.id === TaskType.agreement
    )
  ) {
    return false;
  }

  return [EmployeeRouteTypes.author, EmployeeRouteTypes.executor].some((i) => activePoint.pointRoleId === i);
});

export const getIsShowSendToOuterApprovementButton = createSelector(
  getActivePointsOnMainVertical,
  getAllCurrentUserPoints,
  getDocumentRoute,
  (activePoints, userPoints, route) => {
    if (!activePoints || activePoints.length !== 1) {
      return false;
    }

    const activePoint = activePoints[0];
    const activePhases = [RoutePhaseTypes.projectCreatedDevelopment, RoutePhaseTypes.projectCreatedRework];
    if (
      !(
        activePhases.some((a) => a === activePoint.phaseTypeId) &&
        [TaskType.sendToApprovement, TaskType.revision].includes(activePoint.lastTask.taskType.id)
      )
    ) {
      return false;
    }

    if (userPoints.includes(activePoint) && activePoint.lastTask.taskType.id === TaskType.sendToApprovement) {
      return true;
    }

    const nextPoint = RouteStaticHelpers.getNextNotDeletedPoints(route, activePoint)[0];

    if (nextPoint.phaseTypeId === RoutePhaseTypes.projectCreatedApprovement) {
      return false;
    }

    return userPoints.some(
      (p) =>
        [EmployeeRouteTypes.author].includes(p.pointRoleId) &&
        [
          RoutePhaseTypes.projectCreatedDevelopment,
          RoutePhaseTypes.projectCreatedApprovement,
          RoutePhaseTypes.projectCreatedRework,
        ].includes(p.phaseTypeId)
    );
  }
);

export const getIsShowStartReworkButton = createSelector(
  getActivePointForCurrentEmployee,
  getActivePointsOnMainVertical,
  getDocumentRoute,
  getDocumentRouteTree,
  (
    activePoint: VertexResponseModel,
    allActivePoints: VertexResponseModel[],
    route: RouteTreeResponseModel,
    tree: TreePhaseModel[]
  ) => {
    if (!activePoint) {
      return false;
    }

    const hasActiveAgreementTask = allActivePoints.some(
      (p) => p.lastTask && p.lastTask.taskType.id === TaskType.agreement
    );

    /* Точки автора в петле доработки ( если такие петли существует ) NPA-8567 */
    const authorPointsFromReworkLoop = route?.vertexes.filter(
      (v) => v.oauEmployeeType === ApproverTypeInShortLoop.author
    );

    if (authorPointsFromReworkLoop.length) {
      /** Если на этапе с петлей доработки автора нет незавершенных точек, то считаю, что в этапе выносилось решение руководителем */
      const isCompletedReworkPhase = (authorReworkPoint: VertexResponseModel): boolean => {
        const pointsInAuthorLoopPhase = route.vertexes.filter((p) => p.phaseId === authorReworkPoint.phaseId);
        return pointsInAuthorLoopPhase.every(
          (point) =>
            point.completed || [VertexState.inactive, VertexState.skipped].includes(point.state) || point.isSystem
        );
      };

      /** Если на всех этапах с петлей автора "выносилось решение", то не запрещаю доработку  */
      const canRework = authorPointsFromReworkLoop.every((authorPoint) => isCompletedReworkPhase(authorPoint));

      if (!canRework) {
        return false;
      }
    }

    if (hasActiveAgreementTask) {
      return false;
    }

    const isCorrectPhase = [RoutePhaseTypes.projectCreatedRework].includes(activePoint.phaseTypeId);

    const isCorrectTaskType = [TaskType.revision].includes(activePoint.lastTask.taskType.id);

    if (!(isCorrectPhase && isCorrectTaskType)) {
      return false;
    }

    const nextPoint = RouteStaticHelpers.getNextNotDeletedPoints(route, activePoint)[0];

    if (nextPoint.phaseTypeId === RoutePhaseTypes.projectCreatedApprovement) {
      return false;
    }

    if (tree) {
      const isActivePhaseTypeList = [PhaseTypeCode.approvement, PhaseTypeCode.development, PhaseTypeCode.rework];
      const parallelPhases = tree.filter((p) => p.isParallel);
      const isActiveParallelPhases = parallelPhases.some((p) => {
        const isActivePhase = p.subPhases.some((subPhase) =>
          isActivePhaseTypeList.includes(subPhase.phaseType.typeCode)
        );
        const isActivePointInParallelStages = p.subPhases.some((subPhase) =>
          allActivePoints.some((aPoint) => aPoint.phaseId === subPhase.phaseId)
        );

        return isActivePhase && isActivePointInParallelStages;
      });

      /** Не отображаем кнопку "Начать доработку" пока на параллельном этапе не закончат работу */
      if (isActiveParallelPhases) {
        return false;
      }
    }

    if (activePoint.lastTask.taskType) {
      return [EmployeeRouteTypes.author, EmployeeRouteTypes.coexecutor].includes(activePoint.pointRoleId);
    }
  }
);

export const getIsShowOuterStartDevelopmentButton = createSelector(
  getActivePointForCurrentEmployee,
  getInactiveInitiationPoints,
  getDocumentRouteStoreData,
  (activePoint, inactiveInitiationPoints, route) => {
    const allowedPhases = [
      RoutePhaseTypes.projectApprovedInitiation,
      RoutePhaseTypes.preliminaryApprovementInitiation,
      RoutePhaseTypes.questionProvidedInitiation,
      RoutePhaseTypes.responsibleExecutorInitiation,
      RoutePhaseTypes.puPmInitiation,
      RoutePhaseTypes.concilationInitiation,
    ];
    if (!activePoint) {
      return false;
    }

    if (!allowedPhases.includes(activePoint.phaseTypeId)) {
      return false;
    }

    if (inactiveInitiationPoints.length) {
      return false;
    }

    if (activePoint.pointRoleId === EmployeeRouteTypes.executor) {
      const prevPoint = RouteStaticHelpers.getPreviousNotDeletedPoints(route, activePoint);
      const nextPoint = RouteStaticHelpers.getNextNotDeletedPoints(route, activePoint);
      if (
        prevPoint &&
        prevPoint[0] &&
        prevPoint[0].pointRoleId === EmployeeRouteTypes.leader &&
        nextPoint &&
        nextPoint[0] &&
        nextPoint[0].pointRoleId === EmployeeRouteTypes.executor
      ) {
        return false;
      }
    }

    return [EmployeeRouteTypes.executor, EmployeeRouteTypes.coexecutor, EmployeeRouteTypes.leader].includes(
      activePoint.pointRoleId
    );
  }
);

export const getIsShowOuterStartApprovementButton = createSelector(
  getActivePointForCurrentEmployee,
  getActivePointsOnMainVertical,
  getDocumentRoute,
  (currentEmployeeActivePoint, allActivePoints, route) => {
    const allowedPhases = [
      RoutePhaseTypes.projectApprovedApprovement,
      RoutePhaseTypes.preliminaryApprovementApprovement,
      RoutePhaseTypes.questionProvidedApprovement,
      RoutePhaseTypes.responsibleExecutorApprovement,
      RoutePhaseTypes.puPmApprovement,
      RoutePhaseTypes.concilationApprovement,
    ];

    if (!currentEmployeeActivePoint) {
      return false;
    }

    const currentEmployeePhase = RouteStaticHelpers.getPhaseByPoint(route, currentEmployeeActivePoint);

    const currentPhaseActivePoints = allActivePoints.filter(
      (v) => currentEmployeePhase.id === RouteStaticHelpers.getPhaseByPoint(route, v).id
    );

    if (currentPhaseActivePoints.length > 1) {
      return false;
    }

    if (
      !(
        allowedPhases.includes(currentEmployeeActivePoint.phaseTypeId) &&
        [TaskType.development, TaskType.revision].includes(currentEmployeeActivePoint.lastTask.taskType.id)
      )
    ) {
      return false;
    }

    return currentEmployeeActivePoint.pointRoleId === EmployeeRouteTypes.executor;
  }
);

export const getIsShowTakeToWork = createSelector(
  getActivePointForCurrentEmployee,
  getDocumentRoute,
  (activePoint, route) => {
    if (
      activePoint &&
      [RoutePhaseTypes.oauPmInitiation, RoutePhaseTypes.oauRegistrationInitiation].includes(activePoint.phaseTypeId)
    ) {
      if (activePoint.pointRoleId === EmployeeRouteTypes.leader) {
        const nextPoints = RouteStaticHelpers.getNextPoints(route, activePoint);
        if (nextPoints && nextPoints[0] && nextPoints[0].phaseId === activePoint.phaseId) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
);

export const getIsShowOauDecide = createSelector(
  getAllCurrentUserPoints,
  getActivePointsOnMainVertical,
  getUserInfo,
  (userPoints, activePoints, userInfo) => {
    const hasUserPoints = userPoints && userPoints.length;
    const hasActivePoints = activePoints && activePoints.length;

    if (!hasActivePoints || !hasUserPoints) {
      return false;
    }

    const routePhaseTypesOau = [
      RoutePhaseTypes.oauPmInitiation,
      RoutePhaseTypes.oauPmApprovement,
      RoutePhaseTypes.oauRegistrationInitiation,
      RoutePhaseTypes.oauRegistrationApprovement,
    ];

    /** Нет активных этапов кроме ОАУ и Проект создан */
    const isNotActivePhasesExceptOauAndProjectCreate = !activePoints.some(
      (p) =>
        ![
          ...routePhaseTypesOau,
          RoutePhaseTypes.projectCreatedDevelopment,
          RoutePhaseTypes.projectCreatedRework,
        ].includes(p.phaseTypeId)
    );

    const isActiveSomeOau = activePoints.some((p) => routePhaseTypesOau.includes(p.phaseTypeId));

    if (!isActiveSomeOau) {
      return false;
    }

    const hasApproverTask = activePoints.some(
      (p) => p.pointRoleId === EmployeeRouteTypes.coordinator && p.employee.id === userInfo.currentUser.employee.id
    );

    if (hasApproverTask) {
      return false;
    }

    const isOauLeader = userPoints.some(
      (p) =>
        p.pointRoleId === EmployeeRouteTypes.leader &&
        [RoutePhaseTypes.oauPmInitiation, RoutePhaseTypes.oauRegistration].includes(p.phaseTypeId)
    );

    return isOauLeader && isNotActivePhasesExceptOauAndProjectCreate;
  }
);

export const getIsShowIntroducedButton = createSelector(getAllCurrentUserPoints, (userPoints) =>
  userPoints.some(
    (p) =>
      p.pointRoleId === EmployeeRouteTypes.introducer && [VertexState.active, VertexState.redirected].includes(p.state)
  )
);

export const getIsShowSendToOauLeaderButton = createSelector(getActivePointForCurrentEmployee, (activePoint) => {
  if (!activePoint) {
    return false;
  }

  if (activePoint.level > 1) {
    return false;
  }

  const allowedPhases = [RoutePhaseTypes.oauPmApprovement];

  if (!allowedPhases.includes(activePoint.phaseTypeId)) {
    return false;
  }

  return activePoint.pointRoleId === EmployeeRouteTypes.coordinator;
});

export const getIsShowSendTemplateInitiationButton = createSelector(
  getInactiveInitiationPoints,
  (inactiveInitiationPoints) => !!inactiveInitiationPoints.length
);

export const getMakeDecision = createSelector(
  getIsShowApproveButton,
  getIsShowStartApprovementButton,
  getIsShowOuterStartApprovementButton,
  getIsShowSendToOuterApprovementButton,
  (showApproveButton, showStartApprovementButton, showOuterStartApprovementButton, showSendToOuterApprovementButton) =>
    showApproveButton ||
    showStartApprovementButton ||
    showOuterStartApprovementButton ||
    showSendToOuterApprovementButton
);

export const getActiveCurrentPointId = createSelector(
  getDocumentRouteStore,
  (documentRoute) => documentRoute.activeCurrentUserPointId
);

export const getCanRequestForCoauthor = createSelector(
  getActivePointOnMainVertical,
  getUserInfo,
  (activePoint, userInfo) => {
    if (activePoint) {
      const currentEmployee = userInfo.currentUser.employee;
      //Текущий пользователь не является актуальным Автором ПД
      const isCurrentUserAuthor = activePoint.employee.id === currentEmployee.id;
      //Роль ОИБ = Сотрудник ОАУ ИЛИ Сотрудник ПУ ИЛИ Администратор)
      const requiredRoles = [OibRoles.npaAdmin, OibRoles.oauEmployee, OibRoles.puEmployee];
      const hasRole = requiredRoles.some((role) => currentEmployee.roles.includes(role));
      //АктивнаяТочкаНаМаршруте = Автор
      const isAuthorActivePoint = activePoint.pointRoleId === EmployeeRouteTypes.author;
      //Этап на Маршруте = Проект Создан.Разработка ИЛИ Проект Создан. Доработка
      const isAllowedPhase = [RoutePhaseTypes.projectCreatedDevelopment, RoutePhaseTypes.projectCreatedRework].includes(
        activePoint.phaseTypeId
      );
      return !isCurrentUserAuthor && isAuthorActivePoint && isAllowedPhase && hasRole;
    }

    return false;
  }
);
