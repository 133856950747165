import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeOptionItem } from '@appShared/components/controls/multi-select-tree/tree-option-item';
import { MultiSelectComponent } from '@appShared/components/controls/multi-select/multi-select.component';
import { DomEventService } from '@libs/projects/component-lib/src/public-api';
import { filter } from 'rxjs/operators';

import { TreeOptionDictionaryModel } from '@appShared/components/controls/multi-select-tree/multi-select-tree.model';

/**
 * Компонент мультивыбора
 */
@Component({
  selector: 'app-multi-select-tree',
  templateUrl: './multi-select-tree.component.html',
  styleUrls: ['./multi-select-tree.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectTreeComponent),
      multi: true,
    },
  ],
})
export class MultiSelectTreeComponent
  extends MultiSelectComponent
  implements OnDestroy, OnInit, ControlValueAccessor, AfterViewInit
{
  /**Список элементов выпадающего списка */
  public optionsList: TreeOptionDictionaryModel[] = [];

  public optionsTree: TreeOptionItem[] = [];

  public optionsMap = new Map<number, TreeOptionItem>();

  private isValueSet = false;

  @Input('optionsList')
  set setOptionsList(optionsList: TreeOptionDictionaryModel[]) {
    this.optionsTree = [];
    this.optionsMap = new Map<number, TreeOptionItem>();
    if (Array.isArray(optionsList)) {
      this.optionsList = optionsList;
      this.optionsList
        .map((o, i) => {
          if (typeof o.selected !== 'undefined') {
            this.isValueSet = true;
          }
          this.optionsMap.set(o.id, new TreeOptionItem(o, i, [], o.selected));
          return o;
        })
        .forEach((o, i) => this.selectOptionsInTree(o));
      this.optionsTree = this.sortItems(this.optionsTree);
      this.mapOptionsObjectsToOptionsFrom();
      // Если isNeedPreSelectAllOptions И ни в одном пункте
      // не было параметра selected, то считаем,
      // что можно выбрать все пункты
      if (this.isNeedPreSelectAllOptions && !this.isValueSet) {
        this.checkAllControl.setValue(true);
      }
      this.isValueSet = true;
    }
  }

  constructor(domEventService: DomEventService, elementRef: ElementRef) {
    super(domEventService, elementRef);
  }

  ngOnInit(): void {
    this.isValueSet = false;
    this.checkAllControl.updateValueAndValidity();
    this.subscribeToCheckAll();
  }

  /** скрыть/показать ветку */
  public showHideSublist(item: TreeOptionItem) {
    item.collapsed = !item.collapsed;
  }

  public resetSelectedLabels(event: Event): void {
    this.checkedOptions = [];
    this.checkOptions();
    event.stopPropagation();
  }

  /** подписаться на чеки аользователя */
  protected subscribeToChecked(): void {
    this.subscr = this.formOptions.valueChanges
      .pipe(filter((values: Object[]) => values.length === this.optionsList.length))
      .subscribe((isCheckedvalues: boolean[]) => {
        const controlValue = [];
        this.selectedLabelList = [];
        const newChecked = [];
        const newUnchecked = [];
        this.optionsList.forEach((option, i) => {
          const optionItem = this.optionsMap.get(option.id);
          if (isCheckedvalues[i]) {
            controlValue.push(option);
            this.selectedLabelList.push(option);
            if (!optionItem.selected) {
              newChecked.push(optionItem);
            }
            optionItem.selected = true;
          } else {
            if (optionItem.selected) {
              newUnchecked.push(optionItem);
            }
            optionItem.selected = false;
          }
        });
        newChecked.forEach((opt) => {
          this.checkAllSublist(opt, controlValue, isCheckedvalues);
        });
        newUnchecked.forEach((opt) => {
          this.uncheckAllSublist(opt, controlValue, isCheckedvalues);
        });
        const countChecked = isCheckedvalues.filter((value) => value === true).length;
        this.onChange(controlValue);
        this.setLabelOfCountChecked(countChecked);
        this.setCheckAll(controlValue.length);
      });
  }

  private selectOptionsInTree(o: TreeOptionDictionaryModel): void {
    const optionItem = this.optionsMap.get(o.id);
    if (!optionItem.item.parentId) {
      this.optionsTree.push(optionItem);
    } else {
      const parent = this.optionsMap.get(optionItem.item.parentId);
      if (parent) {
        parent.subItems.push(optionItem);
      } else {
        this.optionsTree.push(optionItem);
      }
    }
  }

  private uncheckAllSublist(
    optionItem: TreeOptionItem,
    controlValue: TreeOptionDictionaryModel[],
    isCheckedValues: boolean[]
  ) {
    optionItem.subItems.forEach((item) => {
      if (item.selected && isCheckedValues[item.index]) {
        item.selected = false;
        isCheckedValues[item.index] = false;
        this.formOptions.controls[item.index].setValue(false, {
          emitEvent: false,
        });
      }
      this.uncheckAllSublist(item, controlValue, isCheckedValues);
    });
  }

  /** отметить все подглуппы */
  protected checkAllSublist(
    optionItem: TreeOptionItem,
    controlValue: TreeOptionDictionaryModel[],
    isCheckedValues: boolean[]
  ) {
    optionItem.subItems.forEach((item) => {
      if (!item.selected && !isCheckedValues[item.index]) {
        item.selected = true;
        isCheckedValues[item.index] = true;
        this.formOptions.controls[item.index].setValue(true, {
          emitEvent: false,
        });
        controlValue.push(item.item);
        this.selectedLabelList.push(item.item);
      }
      this.checkAllSublist(item, controlValue, isCheckedValues);
    });
  }

  private sortItems(treeOptions: TreeOptionItem[]): TreeOptionItem[] {
    treeOptions
      .filter((option) => option.subItems.length > 0)
      .forEach((option) => (option.subItems = this.sortItems(option.subItems)));
    return treeOptions.sort((o1, o2) => {
      const n1 = o1.item[this.optionLabel] ? o1.item[this.optionLabel] : '';
      const n2 = o2.item[this.optionLabel] ? o2.item[this.optionLabel] : '';
      return n1.localeCompare(n2);
    });
  }
}
