import { Injectable, InjectionToken } from '@angular/core';

import { UserInfoResponseModel } from '@models/user-info.model';
import { Observable, of, timer } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { ReduxService } from '@appCore/services/redux/redux.service';
import { getUserInfo } from '@appCore/store/user-info';
import { UserInfoGet } from '@appCore/store/user-info/user-info.actions';

export const CURRENT_USER_ROLES = new InjectionToken<Map<string, string>>('current user roles');
export const USER_INFO_TOKEN = new InjectionToken<UserInfoResponseModel>('current user info');

export const currentUserRolesFactory = () => {
  return (authGuard: AuthGuard): Map<string, string> => {
    return authGuard.getCurrentUserRoles();
  };
};

export const currentUserInfo = () => {
  return (authGuard: AuthGuard) => {
    return authGuard.getUserInfo();
  };
};

@Injectable()
export class AuthGuard {
  private currentUserRoles: Map<string, string>;
  private userInfo: UserInfoResponseModel;

  constructor(private redux: ReduxService) {}

  canActivate(): Observable<boolean> {
    return this.getFromStoreOrAPI().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  public getCurrentUserRoles(): Map<string, string> {
    return this.currentUserRoles;
  }

  public getUserInfo(): UserInfoResponseModel {
    return this.userInfo;
  }

  private getFromStoreOrAPI(): Observable<UserInfoResponseModel> {
    return timer(0, 10000).pipe(
      switchMap(() => this.redux.selectStore(getUserInfo)),
      tap((userInfo) => {
        if (!userInfo) {
          this.redux.dispatchAction(new UserInfoGet());
        }
      }),
      filter((userInfo) => !!userInfo),
      tap((resUserInfo) => {
        this.userInfo = resUserInfo;
        this.currentUserRoles = new Map(resUserInfo.currentUser.roles.map((role) => [role, role]));
      }),
      first()
    );
  }
}
