import { AfterViewInit, Component, ElementRef, HostBinding, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { TooltipPositions } from '@libs/projects/component-lib/src/public-api';
import { TooltipContentAnimations } from './tooltip-content.animations';
import { ElementPositionModel, TooltipOffsetsModel } from './tooltip-position.model';

@Component({
  selector: 'app-tooltip-content',
  templateUrl: './tooltip-content.component.html',
  styleUrls: ['./tooltip-content.component.scss'],
  animations: TooltipContentAnimations,
})

/** компонента, содержащая контент подсказки */
export class TooltipContentComponent implements AfterViewInit {
  /** накинуть анимацию на компонент */
  @HostBinding('@toggleVisible')
  void;
  /** хост-элемент, содержащий директиву вызова подсказки */
  @Input()
  hostElement: HTMLElement;
  /** строка подсказки */
  @Input()
  content: string;
  /** позиция подсказки относительно хост-элемента */
  @Input()
  position: TooltipPositions;
  /** отступ подсказки от хост-элемента */
  @Input()
  offset: number;
  /** вертикальная позиция подсказки */
  @HostBinding('style.top')
  topPosition: string;
  @HostBinding('style.bottom')
  bottomPosition: string;
  /** горизонтальная позиция подсказки */
  @HostBinding('style.left')
  leftPosition: string;
  /** ширина подсказки */
  @Input()
  width: number;

  @ViewChild('vc', { static: true, read: ViewContainerRef })
  vc!: ViewContainerRef;

  @HostBinding('style.max-width')
  // @ts-ignore
  private get getWidth(): string {
    return this.getStyleSize(this.width);
  }

  public isReadyToShow = false;
  private tooltipPadding = 40;

  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    if (!this.hostElement) {
      return;
    }

    setTimeout(() => {
      this.getPositionTooltip();
    });
  }

  /** вычислить позицию блока подсказки */
  private getPositionTooltip(): void {
    const { top, left, bottom } = this.getTooltipOffsets(this.position);

    this.leftPosition = this.getStyleSize(left);
    this.topPosition = this.getStyleSize(top);
    this.bottomPosition = this.getStyleSize(bottom);
    this.isReadyToShow = true;
  }

  private getTooltipOffsets(position: TooltipPositions): TooltipOffsetsModel {
    let left: number;
    let top: number;
    let bottom: number;
    const hostPosition = this.getPositionAndSizeElement(this.hostElement);
    const bodyHeight = this.getBodyHeight();
    const tooltipHeight = this.getTooltipHeight();
    const width = this.width || this.getTooltipWidth();

    switch (position) {
      case TooltipPositions.right:
        left = hostPosition.left + hostPosition.width + this.offset;
        top = hostPosition.top + hostPosition.height / 2 - tooltipHeight / 2;
        break;

      case TooltipPositions.left:
        left = hostPosition.left - width - this.offset;
        top = hostPosition.top + hostPosition.height / 2 - tooltipHeight / 2;
        break;

      case TooltipPositions.top:
        left = hostPosition.left + hostPosition.width / 2 - width / 2;
        bottom = bodyHeight - hostPosition.top + this.offset;
        break;

      case TooltipPositions.bottom:
      default:
        left = hostPosition.left;
        top = hostPosition.top + hostPosition.height + this.offset;
    }

    const isTooltipOffscreen = top + tooltipHeight + this.tooltipPadding > bodyHeight;
    const isTooltipPositionBottom = position === TooltipPositions.bottom;

    if (isTooltipOffscreen && isTooltipPositionBottom) {
      return this.getTooltipOffsets(TooltipPositions.top);
    }
    return { left, top, bottom };
  }

  /** вычислить позицию и размер элемента */
  private getPositionAndSizeElement(element: HTMLElement): ElementPositionModel {
    const positionAndSizeElement = element.getBoundingClientRect();

    return {
      width: positionAndSizeElement.width,
      height: positionAndSizeElement.height,
      top: positionAndSizeElement.top,
      left: positionAndSizeElement.left,
    };
  }

  /** получиить размер в пикселях */
  private getStyleSize(size: number): string {
    return `${size}px`;
  }

  /** получить высоту body */
  private getBodyHeight(): number {
    return this.getPositionAndSizeElement(document.body).height;
  }

  /**получить высоту элемента подсказки */
  private getTooltipHeight(): number {
    return this.getPositionAndSizeElement(this.element.nativeElement).height;
  }

  /**получить ширину элемента подсказки */
  private getTooltipWidth(): number {
    return this.getPositionAndSizeElement(this.element.nativeElement).width;
  }
}
