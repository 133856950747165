import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import {
  ComponentType,
  ConnectionPositionPair,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { filter, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MenuContextualPopupService {
  constructor(private overlay: Overlay, @Inject(DOCUMENT) private documentRef: Document) {}

  private overlayRef: OverlayRef;
  private sub: Subscription;
  private afterClosed$ = new Subject<any>();
  private onClosed$ = this.afterClosed$.asObservable();

  public open<T>(origin: HTMLElement, component: ComponentType<T>, data?: any): Observable<void> {
    this.close(null);
    this.overlayRef = this.overlay.create(this.getOverlayConfig({ origin }));

    const injector = Injector.create({
      providers: [
        { provide: DATA_INJECTION_TOKEN, useValue: data },
        { provide: OVERLAY_REF_INJECTION_TOKEN, useValue: this.overlayRef },
      ],
    });

    this.overlayRef.attach(new ComponentPortal(component, null, injector));

    this.sub = fromEvent<MouseEvent>(this.documentRef, 'click')
      .pipe(
        filter((event) => {
          const clickTarget = event.target as HTMLElement;
          return clickTarget != origin && !this.overlayRef?.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => {
        this.close(null);
      });
    return this.onClosed$.pipe(take(1));
  }

  private close(data: any): void {
    this.sub?.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.afterClosed$.next(data);
    }
  }
  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    return this.overlay.position().flexibleConnectedTo(origin).withPositions(this.getPositions()).withPush(false);
  }
  private getOverlayConfig({ origin }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: false,
      backdropClass: 'popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }
  private getPositions(): ConnectionPositionPair[] {
    return [
      new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
    ];
  }
}

export const DATA_INJECTION_TOKEN = new InjectionToken<string>('DATA_INJECTION_TOKEN');
export const OVERLAY_REF_INJECTION_TOKEN = new InjectionToken<OverlayRef>('OVERLAY_REF_INJECTION_TOKEN');
